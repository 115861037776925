$(document).ready(function () {

    $('.home-offer__carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 10000,

        prevArrow: '<button type="button" class="slick-arrow slick-prev home-offer__carousel-arrow home-offer__carousel-arrow--prev">Previous</button>',
        nextArrow: '<button type="button" class="slick-arrow slick-next home-offer__carousel-arrow home-offer__carousel-arrow--next">Next</button>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false
                }
            }
        ]
    });

    $('.mobile-search__trigger').click(function () {
        $('.mobile-search').toggleClass('mobile-search--is-active');
    })
    $('.mobile-menu__trigger').click(function () {
        $('.mobile-menu').toggleClass('mobile-menu--is-active');
        $('.mobile-menu__overlay').show();
        $('.mobile-menu__overlay').toggleClass('mobile-menu__overlay--is-active');
    });

    $('.mobile-menu__overlay, .mobile-menu__close').click(function () {
        $('.mobile-menu').toggleClass('mobile-menu--is-active');
        $('.mobile-menu__overlay').hide();
        $('.mobile-menu__overlay').toggleClass('mobile-menu__overlay--is-active');
    });

    // if ($(window).width() > 767) {
    //     $(".dropdown").hover(
    //         function(){ $(this).addClass('open') },
    //         function(){ $(this).removeClass('open') }
    //     );
    // };


})